<template>
  <div>
    <v-card class="ma-2" :height="height">
      <v-card-title class="pa-3">
        <v-btn color="success" @click="openDrawer" class="mx-1" tile> Create </v-btn>
        <v-btn tile outlined color="primary" class="mx-1" @click="$router.push(`/academic/courses`)"> Manage Courses </v-btn>
        <v-btn tile outlined color="primary" class="mx-1" @click="$router.push(`/academic/subjects`)"> Manage Subjects </v-btn>
        <v-btn tile outlined color="primary" class="mx-1" @click="$router.push(`/academic/routine-planner`)"> Manage Routine </v-btn>
        <!-- <v-btn tile outlined color="primary" class="mx-2 mt-2" @click="$router.push('/academic/student-promotion')">Student Promotions</v-btn> -->
        <v-spacer></v-spacer>
        <v-text-field style="max-width: 300px" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
        outlined dense class="my-1"></v-text-field>
      </v-card-title>
      <v-card-text class="pa-3 pt-0">
        <v-container fluid v-if="listOfClasses.length">
          <v-row>
            <v-col v-for="item in listOfClasses" :key="item._id" cols="6" sm="6" md="4" lg="2" class="px-2">
              <v-card outlined @click="$router.push(`classrooms/${item._id}`)" elevation="2">
                <v-card-title class="error lighten-3 py-5 d-flex align-center justify-center">
                  <v-icon size="55" color="error darken-2"> mdi-book-open-variant </v-icon>
                </v-card-title>
                <v-card-text class="pa-3 pb-7">
                  <span class="body-1 font-weight-bold" v-if="item.courseId"> {{ item.course ? item.course.name : 'N/A' }} </span>
                  <span class="body-1 font-weight-bold"> {{ item.name }} </span>
                  <span class="body-1 font-weight-bold" v-if="item.section"> - {{ item.section }} </span> 
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="avatars mx-2">
                    <v-avatar class="mx-n1" size="20">
                      <img src="https://randomuser.me/api/portraits/women/65.jpg">
                    </v-avatar>
                    <v-avatar class="mx-n1" size="20">
                      <img src="https://randomuser.me/api/portraits/men/25.jpg">
                    </v-avatar>
                    <v-avatar class="mx-n1" size="20">
                      <img src="https://randomuser.me/api/portraits/women/25.jpg">
                    </v-avatar>
                    <v-avatar class="mx-n1" size="20">
                      <img src="https://randomuser.me/api/portraits/men/55.jpg">
                    </v-avatar>
                    <v-avatar class="mx-n1" size="20">
                      <img src="https://via.placeholder.com/300/09f/fff.png">
                    </v-avatar>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-alert outlined class="pa-7" type="warning" prominent border="left" v-else>
          No Classrooms
        </v-alert>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" width="450" persistent>
      <v-card elevation="0" height="100%">
        <v-card-title class="primary darken-3 white--text pa-4 pb-3">
          <span class="body-1 font-weight-medium"> {{ modelObj._id ? `Update Classroom` : `Add Classroom`}} </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4 py-2">
          <v-card flat>
            <v-form ref="form">
              <v-container fluid class="pa-0">
                <v-row dense>
                  <v-col cols="12" lg="12">
                    <v-switch inset class="my-1" outlined dense hide-details label="Is Academic" v-model="modelObj.isacademic"></v-switch>
                  </v-col>
                  <template v-if="modelObj.isacademic">
                    <v-col cols="12" lg="12">
                      <v-autocomplete
                        outlined dense hide-details label="Course" v-model="modelObj.courseId" :items="listOfCourses"
                        item-text="name" item-value="_id" validate-on-blur @change="courseChangeHandler"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="12">
                      <v-autocomplete
                        outlined dense hide-details label="Term" v-model="modelObj.termId" :items="listOfTerms"
                        item-text="name" item-value="_id" validate-on-blur
                      ></v-autocomplete> <!-- @change="termChangeHandler" -->
                    </v-col>
                  </template>
                  <v-col cols="12" lg="12" v-else>
                    <v-text-field outlined dense hide-details label="Class name" v-model="modelObj.name"
                    :rules="setValidation('requiredValidation')"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <v-autocomplete outlined dense hide-details label="Class Incharge" v-model="modelObj.incharge" :items="listOfStaffs"
                    item-text="formdata.name" item-value="_id" validate-on-blur multiple chips small-chips></v-autocomplete>
                  </v-col>
                  <v-col cols="6" lg="6">
                    <v-autocomplete
                      outlined dense hide-details label="Section" v-model="modelObj.section" :items="listOfSections"
                      :rules="setValidation('requiredValidation')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" lg="6" align-self="center" class="px-3">
                    <v-checkbox
                      outlined dense hide-details label="Is Active" v-model="modelObj.isactive"
                      class="d-inline-flex"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveUpdateHandler"> {{ modelObj._id ? 'Update' : 'Create' }} </v-btn>
          <v-btn @click="dialog = false; $refs.form.reset();" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      height: window.innerHeight - 79,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      editing: null,
      dialog: false,
      search: '',
      searchauto: '',
      listOfStaffs: [],
      listOfClasses: [],
      listOfCourses: [],
      listOfTerms: [],
      modelObj: {},
      listOfSubjects: [],
      academicYear: null
    }
  },
  watch: {
    dialog (val) {
      if (!val) {
        this.modelObj = { }
      }
    }
  },
  beforeMount () {
    this.$store.dispatch('getSubjects').then((data) => {
      this.listOfSubjects = data
    })
    this.$store.dispatch('getCourses').then((data) => {
      this.listOfCourses = data
      this.getListHandler()
    })
    this.$store.dispatch('getUsersList', { only: [ 2 ] }).then((data) => {
      this.listOfStaffs = data
    })
    this.getAcademicYearData()
  },
  mounted () {
    window.onresize = () => {
      this.height = window.innerHeight - 79
    }
  },
  methods: {
    getImage (min = 550, max = 560) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    openDrawer () {
      this.modelObj = {
        isactive: true,
        section: this.listOfSections[0]
      }
      this.dialog = true
    },
    getListHandler () {
      this.$_execute('get', 'classes')
        .then(({ data }) => {
          data.map((x) => {
            x.image = `https://picsum.photos/200/300?image=${this.getImage()}`
            if (x.courseId) {
              x.course = this.listOfCourses.find(course => course._id === x.courseId)
            }
          })
          this.listOfClasses = data
        })
    },
    saveUpdateHandler () {
      if (!this.academicYear || !this.academicYear._id) {
        this.$root.$emit('snackbar', { color: 'error', message: 'Academic year not found! Please set academic year in settings.' })
        return
      }
      if (this.$refs.form && this.$refs.form.validate()) {
        let url = this.modelObj._id ? `classes/${this.modelObj._id}` : 'classes'
        let method = this.modelObj._id ? 'put' : 'post'
        let payload = this.$_copy(this.modelObj)
        payload.subjects = []
        if (payload.subjectids && payload.subjectids.length) {
          payload.subjectids.forEach(x => {
            if (x._id) payload.subjects.push({ subject: x._id, staff: null })
          })
        }
        if (payload.incharge && payload.incharge.length) {
          payload.incharge = payload.incharge.map((staffId, index) => ({
            staffId,
            priority: (index + 1)
          }))
        }
        payload.academicYearId = this.academicYear._id
        this.$_execute(method, url, payload)
          .then(() => {
            this.$root.$emit('snackbar', { message: payload._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success' })
            this.dialog = false
            this.getListHandler()
          })
      } else {
        this.$root.$emit('snackbar', {
          color: 'error',
          message: 'Please check the required fields!'
        })
      }
    },
    courseChangeHandler () {
      const selectedCourse = this.listOfCourses.find((item) => item._id === this.modelObj.courseId)
      this.listOfTerms = selectedCourse.terms
    },
    // termChangeHandler () {
    //   const existingClassItems = this.listOfClasses.filter((item) => item.courseId === this.modelObj.courseId && item.termId === this.modelObj.termId)
    //   const newSectionName = this.listOfSections.find((section) => !existingClassItems.map((item) => item.section).includes(section)) || ''
    //   console.log(newSectionName)
    // },
    getAcademicYearData () {
      this.$_execute('get', 'academic-year')
        .then(({ data = [] } = {}) => {
          const activeAY = data.find((item) => item.isactive)
          this.academicYear = activeAY || null
        })
    }
  }
}
</script>
